import React, { Component } from 'react';
import { hotelPalomarUrl } from '../../constants';
import './Travel.scss';
import Divider from '../../components/Divider/Divider';
import Link from '../../components/Link/Link';

export default class Travel extends Component {

  constructor() {
    super();
    this.state = {};
  }

  render () {
    const mapWidth = Math.min(window.innerWidth, 650);
    const { onPathChange } = this.props;

    return <div className="Travel">
      <h1>Travel</h1>
      <section>
        <p className="headline">Planes, Trains, and Automobiles</p>
      </section>

      <section id="airport-arrival">
        <h2>Arriving by Air</h2>
        <p>Chicago is serviced by two major airports that are both accessible by train, rideshare, or taxi. If you are arriving during rush hour, the easiest route into the city is <a href="https://www.transitchicago.com/airports/" target="_blank" rel="noopener noreferrer">via train</a>. You can purchase tickets at the station or use a contactless payment method like ApplePay or GooglePay. Otherwise, rideshare services Uber and Lyft or standard Taxis are both convenient ways to get into the city and easier than lugging your baggage up and down flights of stairs.</p>
        <h3>O'Hare (ORD)</h3>
        <p>The CTA Blue Line runs from O'Hare to downtown, costs $5 per person, and takes about 45 minutes. We recommend taking the train to the <a href="https://goo.gl/maps/t4xETYX2ABDmMU1D7" target="_blank" rel="noopener noreferrer">Clark/Lake stop</a> and walking the last few blocks to the Kimpton Palomar.</p>
        <p>Rideshare services Uber and Lyft are available from the <strong>departures</strong> level at O'Hare, which is one level UP from the arrivals and baggage claim level. The apps do a decent job of describing where to go and there is signage as well. Prices vary depending on demand, expect to pay about $40 most times.</p>
        <p>Taxis are available at the arrivals level. All taxis take cash or credit. Expect to pay about $40.</p>

        <h3>Midway (MDW)</h3>
        <p>The CTA Orange Line runs from Midway to downtown (aka the Loop), costs $2.50 per person, and takes about 30 minutes. There are two ways to get to the hotel via the orange line. 1) <a href="https://goo.gl/maps/eLrnqE54tBirsd369" target="_blank" rel="noopener noreferrer">Take the orange line from Midway</a> to Roosevelt station and transfer to the red line, then take the red line North toward Howard to the Grand Avenue station. The Kimpton Palomar is conveniently located just outside the Grand Avenue station. Or 2) <a href="https://goo.gl/maps/eMgVV6CSm2MRkU3j8" target="_blank" rel="noopener noreferrer">Take the orange line all the way</a> to Clark/Lake and walk the rest of the way.</p>
        <p>Rideshare services Uber and Lyft are available from the arrivals level outside Door 4. The apps do a decent job of describing where to go and there is signage as well. Prices vary depending on demand, expect to pay about $25 most times.</p>
        <p>Taxis are available at the arrivals level. All taxis take cash or credit. Expect to pay about $35.</p>
      </section>

      <Divider />

      <section id="car-arrival">
        <h2>Arriving by Car</h2>
        <p>Traffic can be bad in Chicago so we generally recommend using Google Maps or Waze and following their suggested routes. There are many alternate routes that can be faster in different traffic conditions.</p>
        <p>Coming from Ohio you'll likely be approaching the city from the East on I-90 or I-65. There are two options to enter the city: I-90 (aka the Skyway) or I-94. The Skyway has about $8 in tolls (cash, card, EZ Pass), and is usually about 10-15 minutes faster than I-94. Both 90 and 94 eventually merge South of downtown. From there, follow the signs for Lakeshore Drive North/ US-41 N/ I-55 E. You'll exit Lakeshore Drive onto Columbus Drive and pass through Grant Park. You'll turn left onto Lower Wacker Drive, then right onto Lower Michigan Avenue, left onto Lower North Water Street which turns into Kinzie Street, and finally right onto State Street. The area around Lower Wacker Drive can be confusing and has poor GPS connectivity, so be sure to look at the map in advance.</p>
      </section>

      <Divider />

      <section>
        <h2>Parking</h2>
        <div>
          <p>Overnight parking in downtown Chicago is expensive. The rate at the hotel is $62 per night. We recommend checking out <a href="https://spothero.com/chicago-parking" target="_blank" rel="noopener noreferrer">Spot Hero</a> or <a href="https://www.parkwhiz.com/" target="_blank" rel="noopener noreferrer">Park Whiz</a> for better rates. These apps allow you to reserve a parking spot in a normal public or private garage and typically offer deeply discounted rates if you reserve in advance. Keep in mind most hotels allow in/out priveliges (you can come and go as you please) whereas many independent garages do not (once you exit, you have to pay again to re-enter). Once you are settled in the hotel, you shouldn't have much need for a car anyway.</p>
          <p>There is ample parking at the wedding venue itself along both sides of Armour St and Hubbard St. Please see map for details.</p>
        </div>
      </section>

      <Divider />

      <section id="ignite">
        <h2>Venue</h2>
        <p>The ceremony and reception will both be held at the same location. <Link path="/events" onClick={onPathChange}>Shuttle service</Link> will be available between the hotel and the venue.</p>
        <p>Street parking is available along both sides of Armour St and Hubbard St. Please see map for details.</p>
        <div className="location-card">
          <h3>Ignite Glass Studios</h3>
          <p>
            401 N. Armour Street
            <br />
            Chicago, IL 60642
          </p>
        </div>
        <iframe width={mapWidth} height="350" frameBorder="0" style={ { border: 0 } } title="Ignite Glass Studios" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJnf3V-S4tDogRTj6krjDhXO0&key=AIzaSyALkWLb_cYUMWXSgtvSAp9QdBpGoasIeYc" allowFullScreen></iframe>
      </section>

      <Divider />

      <section id="rock-bottom">
        <h2>Welcome Party</h2>
        <p>The Friday night event is open to all and conveniently located across the street from the Hotel Palomar and just steps from the Grand Red Line station.</p>
        <div className="location-card">
          <h3>Rock Bottom Restaurant &amp; Brewery</h3>
          <p>
            1 W Grand Avenue
            <br />
            Chicago, IL 60610
          </p>
        </div>
        <iframe width={mapWidth} height="350" frameBorder="0" style={ { border: 0 } } title="Rock Bottom Restaurant and Brewery" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJPXkz9a0sDogREh1AUEtVcCs&key=AIzaSyALkWLb_cYUMWXSgtvSAp9QdBpGoasIeYc" allowFullScreen></iframe>
      </section>

      <Divider />

      <section id="hotel-palomar">
        <h2>Hotel</h2>
        <p>A block of rooms has been reserved with a special rate for our guests. The rate is good Thursday, Friday, and Saturday nights.</p>
        <p>Please use <a href={hotelPalomarUrl} target="_blank" rel="noopener noreferrer">this link</a> to book or mention the Poppenhouse/Mazanec block when calling. Don't hesitate to contact us if you have trouble booking or are unable to see the group rate.</p>
        <p><strong>Update:</strong> The group reservation window has closed. Please contact us if we can help you find accomodations.</p>
        <div className="location-card">
          <h3>Kimpton Hotel Palomar</h3>
          <p>
            505 N. State St.
            <br />
            Chicago, IL 60654
            <br />
            1-877-731-0505
          </p>
          <p><a href={hotelPalomarUrl} target="_blank" rel="noopener noreferrer">Book Now</a></p>
        </div>
        <iframe width={mapWidth} height="350" frameBorder="0" style={ { border: 0 } } title="Kimpton Hotel Palomar" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJL71T8K0sDogRUY9njEkS42M&key=AIzaSyALkWLb_cYUMWXSgtvSAp9QdBpGoasIeYc" allowFullScreen></iframe>
      </section>
    </div>;
  }
};
