import React, { Component } from 'react';
import './Rsvp.scss';

import Divider from '../../components/Divider/Divider';
import RadioSet from '../../components/RadioSet/RadioSet';

export default class Rsvp extends Component {

  constructor() {
    super();
    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this);
    this.handleSaveError = this.handleSaveError.bind(this);
  }

  handleChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log('Saving RSVP...');
    console.log(this.state);
    fetch('http://mazanec.poppen.house/api/rsvps', {
      method: "POST",
      body: JSON.stringify(this.state)
    }).then(this.handleSaveSuccess, this.handleSaveError);
    this.setState({ notice: 'loading' });
  }

  handleSaveSuccess(response) {
    console.log(response);
    this.setState({ notice: 'success' });
  }

  handleSaveError(error) {
    console.log(error);
    this.setState({ notice: 'error' });
  }

  render () {
    const handleChange = this.handleChange;
    const state = this.state;

    return <div className="Rsvp">
      <h1>RSVP</h1>
      <form onSubmit={this.handleSubmit}>
        <p>We're so excited for you to join us on our big day! Whether you can make it to Chicago or not, we're happy to share this moment with you.</p>
        <Divider />
        <section>
          <p>
            Please tell us the names of all the guests in your party
            <br/>
            and the email we can use to confirm your RSVP.</p>
          <label htmlFor="names">Name(s)</label>
          <textarea name="names" rows="3" autoComplete="name" placeholder="Tommy Poppenhouse, Keith Mazanec, Max the Cat" value={state.names} onChange={handleChange}/>

          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="email" autoComplete="email" placeholder="you@email.com" onChange={handleChange} />
        </section>

        <Divider />

        <section>
          <h3>Friday, June 14</h3>
          <p>Will you be joining us on Friday for the welcome party?</p>
          <RadioSet onChange={handleChange} name="attend_fri" value={state.attend_fri} />
        </section>

        <section>
          <h3>Saturday, June 15</h3>
          <p>Will you be joining us on Saturday for the ceremony and reception?</p>
          <RadioSet onChange={handleChange} name="attend_sat" value={state.attend_sat} />

          { state.attend_sat === 'true' && <>
            <p>Do you plan on riding the shuttle from the hotel to the reception?</p>
            <RadioSet onChange={handleChange} name="shuttle_to_venue" value={state.shuttle_to_venue} />

            <p>Do you plan on riding the shuttle from the reception back to the hotel?</p>
            <RadioSet onChange={handleChange} name="shuttle_to_hotel" value={state.shuttle_to_hotel} />
          </>}
        </section>

        <Divider />

        <section>
          { state.attend_sat === 'true' && <>
            <p>Please tell us more about your party</p>
            <label htmlFor="diet">Dietary Restrictions</label>
            <textarea name="diet" rows="3" placeholder="Nut allergy, vegan, gluten free..." value={state.diet} onChange={handleChange} />

            <label htmlFor="accommodations">Additional Accommodations</label>
            <textarea name="accommodations" rows="3" placeholder="Is there anything we can do to make your experience more comfortable?" value={state.accommodations} onChange={handleChange} />
          </>}

          <label htmlFor="message">Message</label>
          <textarea name="message" rows="3" placeholder="Anything else you'd like us to know?" value={state.message} onChange={handleChange} />
        </section>

        <Divider />

        <section>
          <input type="submit" value="Send RSVP" disabled={state.notice === 'loading'}/>
          { state.notice === 'loading' &&
            <p>Sending RSVP...</p>
          }
          { state.notice === 'success' &&
            <p>Thanks for your RSVP! If you need to change anything, feel free to use this form again.</p>
          }
          { state.notice === 'error' &&
            <p>Something went wrong. Please try again, or contact us at: k.mazanec@gmail.com</p>
          }
        </section>
      </form>
    </div>;
  }
};
