import React, { Component } from 'react';
import './Events.scss';

export default class Events extends Component {

  constructor() {
    super();
    this.state = {};
  }

  render () {
    return <div className="Events">
      <h1>Events</h1>

      <section>
        <p className="headline">We're so excited, and we just can't hide it!</p>
      </section>

      <section>
        <h3>Friday, June 14, 2019</h3>
        <dl>
          <dt>3:00 pm - Hotel Checkin Opens</dt>
          <dd>
            <p className="location">
              <a href="/travel#hotel-palomar">Hotel Palomar</a>
            </p>
          </dd>
          <dt>7:00 pm - 10:00 pm - Welcome Party</dt>
          <dd>
            <p>
              Please join us at Rock Bottom to kick off the weekend. All are invited to attend!
              <br />
              Food and soft drinks will be provided. Cash bar.
            </p>
            <p className="location">
              <a href="/travel#rock-bottom">Rock Bottom Restaurant &amp; Brewery</a>
            </p>
          </dd>
        </dl>
      </section>

      <section>
        <h3>Saturday, June 15, 2019</h3>
        <dl>
          <dt>4:00 pm - Pre-Party</dt>
          <dd>
            <p>Arriving before 5:00 pm? Head over to <a href="http://districtbrewyards.com" target="_blank" rel="noopener noreferrer">District Brew Yards</a> around the corner from Ignite where you can hang out before the ceremony and sample 40 taps from 3 different breweries.</p>
          </dd>
          <dt>4-5 pm - Shuttle Service</dt>
          <dd>
            <p>Departing from the Hotel Palomar at <strong>4:00 pm and 4:45 pm</strong></p>
            <p>The 4:00 pm shuttle will drop off at <a href="http://districtbrewyards.com" target="_blank" rel="noopener noreferrer">District Brew Yards</a>.</p>
            <p>The 4:45 pm shuttle will drop off at Ignite.</p>
            <p>Running late? There will be one final shuttle run departing around 5:15 pm. Life happens, we get it.</p>
          </dd>
          <dt>5:30 pm - 12:00 am - Ceremony and Reception</dt>
          <dd>
            <p className="location">
              <a href="/travel#ignite">Ignite Glass Studios</a>
            </p>
          </dd>
          <dt>5:30 pm - Wedding Ceremony</dt>
          <dd><p>We say "I do" in the courtyard</p></dd>
          <dt>6:00 pm - Cocktail Hour</dt>
          <dd><p>Have a drink, mingle, and take in the skyline views from the rooftop</p></dd>
          <dt>7:00 pm - Dinner, Drinks, Dancing</dt>
          <dd>
            <p>Celebrate with us!</p>
          </dd>
          <dt>10 pm -12 am - Shuttle Service</dt>
          <dd>
            <p>Departing from Ignite. <strong>10 pm, 11 pm, and 12 am</strong></p>
            <p>Need to leave early? The shuttle will be available by request starting at 8:30 pm.</p>
          </dd>
          <dt>12:00 am - After Party</dt>
          <dd><p>Location TBD</p></dd>
        </dl>
      </section>

      <section>
        <h3>Sunday, June 16, 2019</h3>
        <dl>
          <dt>8:00 am - 2:00 pm</dt>
          <dd><p>Brunch is available at the Hotel Palomar's restaurant, <a href="https://sablechicago.com/menu" target="_blank" rel="noopener noreferrer">Sable</a>. We may make an appearance. There are also countless brunch spots in the area if you'd like to explore.</p></dd>
          <dt>12:00 pm</dt>
          <dd><p>Hotel Checkout</p></dd>
        </dl>
        <div></div>
      </section>
    </div>;
  }
};
