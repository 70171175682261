import React from 'react';

import './Star.scss';
import starImg from '../../img/star_A.png';

export default (props) => (
  <div className="Star">
    <a href="/?utm_content=star"><img src={starImg} alt="Star" /></a>
  </div>
)
