import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import App from './App/App';
import registerServiceWorker from './registerServiceWorker';

(function ({ location: { host } }) {
  if (host === 'mazanec.poppen.house') {
    ReactGA.initialize('UA-130685841-1');
  } else if (host === 'mazanec.poppen.haus') {
    ReactGA.initialize('UA-130685841-2');
  } else {
    console.log(`Host: ${host} is not recognized. Using default GA.`);
    ReactGA.initialize('UA-130685841-1');
  }
}(window));

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
