import React, { Component } from 'react';
import ReactGA from 'react-ga';

import './Nav.scss';

const NavLi = ({ onClick, children = '' }) => {
  const path = `/${children.toLowerCase()}`;
  const active = window.location.pathname === path;

  return <li className={active ? 'active' : ''}>
    <a href={path} onClick={onClick}>{ children }</a>
  </li>;
};

const bindAll = (ctx, ...fns) => fns.map(fn => ctx[fn] = ctx[fn].bind(ctx));

export default class Nav extends Component {
  constructor() {
    super();

    bindAll(this, 'handleClick', 'handleToggle');

    this.state = {
      active: false
    };
  }

  handleClick(event) {
    event.preventDefault();
    this.setState({ active: false });
    this.props.onPathChange(event.target.pathname);
  }

  handleToggle() {
    const { active } = this.state;

    ReactGA.event({
      category: 'Navigation',
      action: 'Toggle Tap',
      label: active ? 'Close' : 'Open'
    });

    this.setState({ active: !active });
  }

  render() {
    const { active } = this.state;
    const handleClick = this.handleClick;

    return (
      <nav className="Nav">
        <div className="Nav-toggle">
          <button
            className={`hamburger hamburger--collapse ${active ? 'is-active' : ''}`}
            type="button"
            onClick={this.handleToggle}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
        <ul className={active ? 'active' : ''}>
          <NavLi onClick={handleClick}>Travel</NavLi>
          <NavLi onClick={handleClick}>Events</NavLi>
          <NavLi onClick={handleClick}>Credits</NavLi>
          <li className={window.location.pathname === '/' ? 'active' : '' }>
            <a href='/' onClick={handleClick}>Home</a>
          </li>
        </ul>
      </nav>
    );
  }
}
