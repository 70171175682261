
import React from 'react';

export default ({ onChange, name, value }) => (
  <div className="radio-set">
    <div className="radio-set-option">
      <input type="radio" name={name} id={`${name}-yes`} checked={value === 'true'} value="true" onChange={onChange}/>
      <label htmlFor={`${name}-yes`}>Yes</label>
    </div>
    <div className="radio-set-option">
      <input type="radio" name={name} id={`${name}-no`} checked={value === 'false'} value="false" onChange={onChange}/>
      <label htmlFor={`${name}-no`}>No</label>
    </div>
  </div>
);
