import React, { Component } from 'react';
import ReactGA from 'react-ga';

import './App.scss';

// Components
import Nav from '../components/Nav/Nav';
import Star from '../components/Star/Star';

// Pages
import Credits from '../pages/Credits/Credits';
import Events from '../pages/Events/Events';
import Home from '../pages/Home/Home';
import Registry from '../pages/Registry/Registry';
import Rsvp from '../pages/Rsvp/Rsvp';
import Travel from '../pages/Travel/Travel';

const routes = {
  '/credits': Credits,
  '/rsvp': Rsvp,
  '/events': Events,
  '/travel': Travel,
  '/registry': Registry,
  '/': Home,
  notFound: Home
};

class App extends Component {
  constructor(props) {
    super();

    this.handlePathChange = this.handlePathChange.bind(this);

    this.state = {
      path: window.location.pathname
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', (event) => {
      const path = window.location.pathname;
      ReactGA.pageview(path + window.location.search);
      this.setState({ path });
    });

    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const { path } = this.state;
    const Page = routes[path] || routes.notFound;

    return (
      <div className="App Wedding">
        <div className="page-container">
          <Page onPathChange={this.handlePathChange} />
        </div>
        <Star />
        <footer className="App-footer">
          <Nav onPathChange={this.handlePathChange} />
        </footer>
      </div>
    );
  }

  handlePathChange(path) {
    window.history.pushState(null, '', path);
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({ path });
  }
}

export default App;
