import React, { Component } from 'react';
import './Registry.scss';
import Divider from '../../components/Divider/Divider';

export default (props) => (
  <div className="Registry">
    <h1>Registry &amp; Gifts</h1>

    <section>
      <p>
        Your presence on our special day is the greatest present of all! We are lucky enough to already have nearly everything we need for this new chapter together.
      </p>
      <p>
        However, for friends and family who have expressed an interest, we have created a gift registry for your convenience. Alternately, a contribution to our honeymoon fund would be warmly appreciated.
      </p>
    </section>

    <section>
      <h3>Crate &amp; Barrel</h3>
      <a href="https://www.crateandbarrel.com/gift-registry/keith-mazanec-and-tommy-poppenhouse/r5974425" target="_blank" rel="noopener noreferrer">View Registry</a>
    </section>

    <section>
      <h3>Target</h3>
      <a href="https://www.target.com/gift-registry/giftgiver?registryId=d02d41cafba8428a9ceca792261af9f0&type=WEDDING" target="_blank" rel="noopener noreferrer">View Registry</a>
    </section>

    <section>
      <Divider />
    </section>
  </div>
);
