import React, { Component } from 'react';
import './Credits.scss';
import Divider from '../../components/Divider/Divider';

import bbqShrimp from '../../img/bbq_shrimp.jpg';
import buffet from '../../img/buffet.jpg';
import cake from '../../img/cake.jpg';
import ceremonyJess from '../../img/ceremony_jess.jpg';
import dinnerKeithHands from '../../img/dinner_keith_hands.jpg';
import djJessi from '../../img/dj_jessi.jpg';
import family from '../../img/family.jpg';
import glassFurnace from '../../img/glass_furnace.jpg';
import happily from '../../img/happily_ever_after.jpg';
import jumpingForJoy from '../../img/jumping_for_joy.jpg';
import keithDancingBlur from '../../img/keith_dancing_blur.jpg';
import pinning from '../../img/pinning_bouttennier.jpg';
import pork from '../../img/pork.jpg';
import prideGroup from '../../img/pride_group.jpg';
import tableSetting from '../../img/table_setting.jpg';

export default (props) => (
  <div className="Credits">
    <h1>Credits</h1>

    <section>
      <p className="headline">A huge <b>THANK YOU</b> to all the incredible people who made this day possible!</p>
    </section>

    <section>
      <span className="tag">Officiant</span>
      <h3>Jess DeBacco</h3>
      <p></p>
      <img className="frame" src={ceremonyJess} alt="Wedding ceremony" />
    </section>

    <section>
      <span className="tag">Music</span>
      <h3>Toast &amp; Jam</h3>
      <h4>Jessi DiBartolomeo</h4>
      <p className="contact"><a href="http://toastandjamdjs.com" target="_blank" rel="noopener noreferrer">toastandjamdjs.com</a></p>
      <p></p>
      <img className="frame vertical jessi" src={djJessi} alt="DJ Jessi" />
    </section>

    <section>
      <span className="tag">Photography</span>
      <h3>Winter Song Photography</h3>
      <h4>Rio and Ellegra Chavez</h4>
      <p className="contact"><a href="https://www.wintersongphotography.com/about-chavez" target="_blank" rel="noopener noreferrer">wintersongphotography.com</a></p>
      <p></p>
      <img className="frame" src={family} alt="Poppenhouse Mazanec families" />
    </section>

    <section>
      <span className="tag">Coordinator</span>
      <h3>Dana Day Of</h3>
      <h4>Dana Schmidt</h4>
      <p className="contact"><a href="http://www.danadayof.com" target="_blank" rel="noopener noreferrer">danadayof.com</a></p>
      <p></p>
      <img className="frame" src={dinnerKeithHands} alt="Dinner table plate" />
    </section>

    <section>
      <span className="tag">Flowers</span>
      <h3>Flowers for Dreams</h3>
      <h4>Jason W.</h4>
      <p className="contact"><a href="https://flowersfordreams.com" target="_blank" rel="noopener noreferrer">flowersfordreams.com</a></p>
      <p></p>
      <img className="frame vertical" src={tableSetting} alt="Dinner table with place settings" />
    </section>

    <section>
      <span className="tag">Catering</span>
      <h3>Entertaining Company</h3>
      <h4>Michelle Sterling</h4>
      <p className="contact"><a href="https://entertainingcompany.com" target="_blank" rel="noopener noreferrer">entertainingcompany.com</a></p>
      <p></p>
      <img className="frame vertical" src={buffet} alt="Dinner buffet" />
    </section>

    <section>
      <span className="tag">Dinner</span>
      <h3>Luella's Southern Kitchen</h3>
      <p className="contact"><a href="http://luellassouthernkitchen.com" target="_blank" rel="noopener noreferrer">luellassouthernkitchen.com</a></p>
      <p></p>
      <img className="frame" src={pork} alt="Roast Pork" />
    </section>

    <section>
      <span className="tag">Dessert</span>
      <h3>Jennivee's Bakery</h3>
      <h4>Jenne Vailoces</h4>
      <p className="contact"><a href="https://www.yelp.com/biz/jennivees-bakery-chicago" target="_blank" rel="noopener noreferrer">Jenivees</a></p>
      <p></p>
      <img className="frame vertical" src={cake} alt="Wedding cake" />
    </section>

    <section>
      <span className="tag">Beverages</span>
      <h3>Binny's Beverage Depot</h3>
      <p className="contact"><a href="https://www.binnys.com" target="_blank" rel="noopener noreferrer">binnys.com</a></p>
      <p></p>
      <img className="frame vertical" src={keithDancingBlur} alt="Keith dancing" />
    </section>

    <section>
      <span className="tag">Venue</span>
      <h3>Ignite Glass Studio</h3>
      <p className="contact"><a href="https://igniteglass.com" target="_blank" rel="noopener noreferrer">igniteglass.com</a></p>
      <p></p>
      <img className="frame vertical" src={glassFurnace} alt="Glass blowing furnace" />
    </section>

    <section>
      <span className="tag">Accommodations</span>
      <h3>Kimpton Hotel Palomar</h3>
      <p className="contact"><a href="https://www.hotelpalomar-chicago.com" target="_blank" rel="noopener noreferrer">hotelpalomar-chicago.com</a></p>
      <p></p>
      <img className="frame happily" src={happily} alt="Keith and Tommy walking away" />
    </section>

    <section>
      <span className="tag">Transportation</span>
      <h3>Windy City Limos</h3>
      <p className="contact"><a href="https://windycitylimos.com" target="_blank" rel="noopener noreferrer">windycitylimos.com</a></p>
      <p></p>
    </section>

    <section>
      <Divider />
    </section>
  </div>
);
