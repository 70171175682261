
const hotelPalomarUrl = 'https://www.kimptonhotels.com/redirect?path=asearch&brandCode=KI&localeCode=en&regionCode=1&hotelCode=ORDCH&checkInDate=14&checkInMonthYear=052019&checkOutDate=16&checkOutMonthYear=052019&rateCode=6CBARC&_PMID=99801505&GPC=LPM&ratesordertype=rc&viewfullsite=true';

const photoGalleryUrl = 'https://riochavezphotography.pixieset.com/poppenzanecbigday/';

const honeymoonGalleryUrl = 'https://www.icloud.com/sharedalbum/#B0R5n8hH4ccF5L';

const winterSongUrl = 'https://www.wintersongphotography.com/';

export {
  honeymoonGalleryUrl,
  hotelPalomarUrl,
  photoGalleryUrl,
  winterSongUrl,
};
