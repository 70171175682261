import React from 'react';
import './Home.scss';

import bannerImage from '../../img/tommy_keith_brewery_kiss.jpg';
import Link from '../../components/Link/Link';
import Divider from '../../components/Divider/Divider';

import {
  photoGalleryUrl,
  winterSongUrl,
  honeymoonGalleryUrl,
} from '../../constants';

export default (props) => (
  <div className="Home">
    <div className="title">
      <h1>Thank You!</h1>
    </div>

    <div className="image-container">
      <img className="hero" src={bannerImage} alt="Keith and Tommy brewery kiss" />
      <p className="image-credit">Photo by <a href={winterSongUrl} target="_blank" rel="noopener noreferrer">Winter Song Photography</a></p>
    </div>

    <section>
      <h2>Tommy &amp; Keith</h2>
      <h3>6.15.19</h3>
    </section>

    <section>
      <Divider />
      <a href={photoGalleryUrl} target="_blank" rel="noopener noreferrer">Wedding Gallery</a>
      <span>&nbsp;|&nbsp;</span>
      <Link path="/credits" onClick={props.onPathChange}>Credits</Link>
      <span>&nbsp;|&nbsp;</span>
      <a href={photoGalleryUrl} target="_blank" rel="noopener noreferrer">Honeymoon Gallery</a>
    </section>
  </div>
);

