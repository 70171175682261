
import React, { Component } from 'react';

import './Link.scss';

export default class Link extends Component {
  constructor() {
    super();
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(this.props.path);
  }

  render() {
    const { path, children } = this.props;

    return (
      <a href={path} onClick={this.handleClick} className="Link">{ children }</a>
    );
  }
};
